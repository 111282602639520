<template>
  <v-container fluid>
    <PageHeaderSlot></PageHeaderSlot>

    <v-card>
      <v-card-text class="px-2">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('studentCode') }}</label>
            <div>{{ formData.student_code }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('campus') }}</label>
            <div>{{ formData.school_name }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('remark') }}</label>
            <div>{{ formData.remark || '-' }}</div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('name') }}</label>
            <div>{{ formData.student_name || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('engName') }}</label>
            <div>{{ formData.student_eng_name || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('grade') }}</label>
            <div v-if="formData.grade">{{ formData.grade |formatGrade }}</div>
            <div v-else>-</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('studentSchool') }}</label>
            <div>{{ formData.student_school_name || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('studentPhone') }}</label>
            <div>{{ formData.phone || '-' }}</div>
          </v-col>
          <v-col cols="12" md="8" lg="6">
            <label class="input-label">{{ $t('homeAddress') }}</label>
            <div>{{ formData.address || '-' }}</div>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('birthday') }}</label>
            <div>{{ formData.birth_date || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('age') }}</label>
            <div>{{ age || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('gender') }}</label>
            <div v-if="formData.gender">{{ formData.gender | formatGender }}</div>
            <div v-else>-</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('memberLevel') }}</label>
            <div v-if="formData.member_level">{{ formData.member_level | formatMemberLevel }}</div>
            <div v-else>-</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('accountName') }}</label>
            <div>{{ formData.username }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-0">
        <v-row class="ma-0 px-2">
          <v-col cols="12">
            <h2 class="colorBlack--text">{{ $t('contactPerson') }}</h2>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="ma-0 px-2 pt-3">
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('contactPersonName', { number: 1 }) }}</label>
            <div>{{ formData.contact_person_1_name || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('contactPersonPhone', { number: 1 }) }}</label>
            <div>{{ formData.contact_person_1_phone || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('contactPersonName', { number: 2 }) }}</label>
            <div>{{ formData.contact_person_2_name || '-' }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <label class="input-label">{{ $t('contactPersonPhone', { number: 2 }) }}</label>
            <div>{{ formData.contact_person_2_phone || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <Datatable
      class="mt-6"
      tableName="performance"
      :isLoading="scoreTableLoading"
      :tableData="scoreTableData"
      :tableHeaders="performanceTableHeaders"
      disableFooter
      disablePagination
    >
        <template v-slot:[`item.semester`]="{ item }">
            <span v-if="item.semester">{{ $t('semesterOption.' + item.semester) }}</span>
        </template>
    </Datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiWhatsapp, mdiPlusCircleOutline } from '@mdi/js'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FormInput from '@/components/formField/FormInput.vue'
import FormSelect from '@/components/formField/FormSelect.vue'
import FormDatePicker from '@/components/formField/FormDatePicker.vue'
import FormDialog from '@/components/FormDialog.vue'

export default {
  name: 'StudentUserDetails',
  components: {
    Datatable,
    ConfirmDialog,
    FormInput,
    FormSelect,
    FormDatePicker,
    FormDialog,
  },
  computed: {
    age() {
      if (this.$validate.DataValid(this.formData.birth_date)) {
        const birthYear = parseInt(this.formData.birth_date.substring(0, 4))
        return (new Date().getFullYear() - birthYear).toString()
      }

      return ''
    },
  },
  data: vm => ({
    id: null,
    formData: {
      school_id: '',
      school_name: '',
      student_name: '',
      student_eng_name: '',
      grade: '',
      student_school_name: '',
      phone: '',
      address: '',
      birth_date: '',
      gender: '',
      member_level: 'normal',
      username: '',
      contact_person_1_name: '',
      contact_person_1_phone: '',
      contact_person_2_name: '',
      contact_person_2_phone: '',
      password: '',
      status: 'active',
      remark: '',
      student_code: ''
    },

    scoreTableData: [],
    scoreTableLoading: false,

    // --- Static data
    icon: {
      mdiWhatsapp,
      mdiPlusCircleOutline,
    },
    performanceTableHeaders: [
      { value: 'create_date', text: vm.$t('createDate') },
      { value: 'grade', text: vm.$t('grade') },
      { value: 'semester', text: vm.$t('semester') },
      { value: 'subject', text: vm.$t('subject') },
      { value: 'exam_name', text: vm.$t('examName') },
      { value: 'score', text: vm.$t('score') },
    ],
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getStudentById() {
      this.scoreTableLoading = true
      try {
        const data = await this.$Fetcher.GetStudentById(this.id)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.formData[key] = data[key]
          }
        }
        await this.getTutorSchool();
        await this.getStudentScores()
      } catch {
        this.setDialogMessage({
          message: 'noData',
          returnLink: { name: 'StudentList' },
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    async getTutorSchool() {
      try {
        const data = await this.$Fetcher.GetSchoolById(parseInt(this.formData.school_id));
        this.formData.school_name = data.name;
      } catch {}
    },

    async getStudentScores() {
      this.scoreTableLoading = true
      this.scoreTableData = []
      try {
        const payload = {
          filter_item: [{ key: 'student_id', value: this.id }],
        }
        const { data } = await this.$Fetcher.GetStudentScores(payload)
        this.scoreTableData = data.map(el => {
          return {
            id: el.id,
            create_date: el.create_date,
            grade: el.grade,
            semester: el.semester,
            subject: el.subject,
            exam_name: el.exam_name,
            score: el.score,
          }
        })
      } catch (err) {
        this.scoreTableData = []
      } finally {
        this.scoreTableLoading = false
      }
    },
  },

  async created() {
    this.$store.dispatch('toggleLoadingPage', true)

    const id = parseInt(this.$route.params.id)
    if (!isNaN(id)) {
      this.id = id
    }

    const check = await this.checkUserData()
    if (check) {
      const userData = this.getCurrentUserData()
      this.id = userData.id
      if (!this.$route.params.username) {
        this.$router.replace({ params: { username: userData.username } })
      }
      this.getStudentById()
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>
